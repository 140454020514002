import { useState } from "react";

const TestFile = () => {
  const [authUrl, setAuthUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');

  return (
    <div>
      Hello
    </div>
  );
}


export default TestFile;